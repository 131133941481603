<template>
  <v-container>
    <v-row v-if="categories">
      <v-tabs v-model="selectedTab">
        <v-tab v-for="(item, index) in categories" :key="index">
          {{ item.name }} ({{ item.yeshLessonCode }})
        </v-tab>
        <v-tab-item v-for="(category, index) in categories" :key="index">
          <p
            v-for="(soril, sorilIndex) in category.sorils"
            :key="'soril' + sorilIndex"
          >
            {{ soril.name }}
          </p>
          <v-data-table
            :items="category.students"
            v-if="category.students"
            :headers="headers"
            hide-default-footer
            :items-per-page="-1"
            :search="search"
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-spacer></v-spacer>
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                  clearable
                ></v-text-field>
              </v-toolbar>
            </template>

            <template slot="item" slot-scope="props">
              <tr>
                <td>{{ props.item.index }}</td>
                <td>{{ props.item.firstName }}</td>
                <template v-if="category.sorils"> 
                  <td v-for="(soril, sorilIndex) in category.sorils" :key="soril.id+sorilIndex"> {{ soril.name }}</td>
                </template>
              </tr>
            </template>

            <!-- <td>a</td>
              <td>aa</td>
            <template v-slot:item.scores="{ item }">
              <td v-if="category.results"> {{ category.results.length }}</td>
              <td>
                <div v-for="(soril,sorilIndex) in category.sorils" :key="item.id+'soril' + sorilIndex">{{ _getScoreOfSoril(item, category, soril)}} <v-divider inset vertical> </v-divider></div>
              </td>
            </template> -->
            <!-- <template v-slot:item.actions="{ item }">
              <v-icon small @click="getSorilScore(item)">mdi-delete</v-icon>
              <span v-if="category.sorils">{{category.sorils.length }}</span>
            </template> -->
          </v-data-table>
        </v-tab-item>
      </v-tabs>
    </v-row>
    <v-row>
      <v-col lg="6">
        <div class="mb-10">
          <h5 class="font-weight-bold text-h5 text-typo mb-0">
            ЭЕШ xичээл сонгосон xүүxдүүдийн жагсаалт
            <span v-if="students">({{ students.length }})</span>
          </h5>
          <p class="text-sm text-body mb-0">
            Энэxүү xуудас нь xичээл сонгосон xүүxдүүдийн мэдээллий xаруулна.
          </p>
        </div>
      </v-col>
    </v-row>
    <v-card-text class="px-0 py-0 scoreTables mt-4">
      <v-simple-table v-if="students && categories">
        <template v-slot:default>
          <thead>
            <tr>
              <th>No</th>
              <th>Name</th>
              <th
                v-for="(lesson, lindex) in categories"
                :key="'lesson' + lindex"
              >
                {{ lesson.name }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(student, sindex) in students" :key="'student' + sindex">
              <td class="text-center">{{ sindex + 1 }}</td>
              <td>
                {{ student.firstName }}
                <span style="color: #aaa">{{ student.lastName }}</span>
              </td>
              <td
                class="text-center"
                v-for="(lesson, lindex) in categories"
                :key="'lessonBody' + lindex"
                :style="
                  checkLesson(student, lesson) == '+'
                    ? 'background-color: #ffeb3b '
                    : ''
                "
              >
                {{ checkLesson(student, lesson) }}
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>
  </v-container>
</template>
<script>
const fb = require("@/firebaseConfig.js");
import { store } from "@/store.js";
import { sync } from "vuex-pathify";
export default {
  name: "light-table-transparent",
  data() {
    return {
      hashtableStudents: null,
      results: null,
      selectedTab: null,
      actionstatus: null,
      search: "",
      snackbar: false,
      students: null,
      categories: null,
      headers: [
        { text: "No.", value: "index", width: "1%" },
        { text: "Name", value: "firstName" },
        { text: "Name", value: "id" },
        { text: "Name", value: "ref.path" },
        { text: "Name", value: "scores" },
        { text: "Actions", value: "actions", sortable: false },
      ],
    };
  },
  computed: {
    ...sync("*"),
    filteredStudents() {
      return null;
    },
  },
  watch: {
    selectedTab(val) {
      console.log(this.categories[val]);
      // this.headers =  [
      //   { text: "No.", value: "index", width: "1%" },
      //   { text: "Name", value: "firstName" },
      //   { text: "Name", value: "id" },
      // ],
      fb.dbeyesh800
        .collection("_public-assignments-allschools")
        .where("yeshLessonCode", "==", this.categories[val].yeshLessonCode)
        .onSnapshot((docs) => {
          this.categories[val].sorils = [];
          docs.forEach((lesson) => {
            let eyesh = lesson.data();
            eyesh.ref = lesson.ref;
            eyesh.id = lesson.id;
            console.log(
              eyesh.yeshLessonCode,
              eyesh.name,
              eyesh.ref.path,
              store.state.userData.school.ref.path
            );
            eyesh.ref
              .collection("results-simplified")
              .where("schoolRef", "==", store.state.userData.school.ref.path)
              .get()
              .then((docs) => {
                this.categories[val].results = [];
                docs.forEach((doc) => {
                  let result = doc.data();
                  result.id = doc.id;
                  result.ref = doc.ref;
                  console.log(result.ref.path)
                  this._findStudent(result);
                  this.categories[val].results.push(result);
                });
              });
            this.categories[val].sorils.push(eyesh);
          });
        });
    },
  },
  methods: {
    _findStudent(result) {
      var student = this.hashtableStudents.get(result.answerer.id);
      student.results.push(result);
    },
    _getScoreOfSoril(student, category, soril) {
      student, soril, console.log(category);
      for (const result of category.results) {
        if (result.answerer.id == student.id && result.shalgaltId == soril.di)
          return 3;
      }
      return "-";
    },
    getSorilScore(student) {
      console.log(student.id);
      var list = this.results.filter(
        (result) => result.answerer.id == student.id
      );
      console.log(list);
    },
    checkLesson(student, lesson) {
      if (lesson.students) {
        var found = lesson.students.find((ele) => ele.id == student.id);
        if (found) {
          return "+";
        } else {
          return "-";
        }
      } else {
        return "-";
      }
    },
  },
  created() {
    if (this.userData.school) {
      fb.dbeyesh800
        .collection("categories")
        .orderBy("yeshLessonCode", "asc")
        .onSnapshot((docs) => {
          this.categories = [];
          docs.forEach((lesson) => {
            let eyesh = lesson.data();
            eyesh.ref = lesson.ref;
            eyesh.id = lesson.id;
            eyesh.students = null;
            eyesh.ref
              .collection("lesson-students")
              .where("ppschool", "==", store.state.userData.school.ref.path)
              .get()
              .then((studs) => {
                eyesh.students = [];
                var counter = 0;
                studs.forEach((stud) => {
                  counter++;
                  let student = stud.data();
                  student.ref = stud.ref;
                  student.id = stud.id;
                  student.index = counter;
                  student.results = []
                  eyesh.students.push(student);
                });
              });
            this.categories.push(eyesh);
          });
        });

      this.userData.school.ref
        .collection("students")
        .where("deleted", "==", false)
        .where("ACADEMIC_LEVEL", "==", "12")
        .orderBy("firstName", "asc")
        .orderBy("lastName", "asc")
        .get()
        .then((students) => {
          this.students = [];
          this.hashtableStudents = new Map();
          students.forEach((student) => {
            let stud = student.data();
            stud.ref = student.ref;
            stud.id = student.id;
            stud.results = [];
            this.students.push(stud);
            this.hashtableStudents.set(stud.id, stud);
          });
        });
    }
  },
};
</script>
<style>
.scoreTables td,
.scoreTables th {
  border: 1px solid #dddddd;
  text-align: left;
  height: 25px !important;
}
</style>
